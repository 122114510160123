<template>
  <div class='main'>
    <div class="header">
      <div class="box">
        <div class="header-left">
          <img src="../../assets/common/logo.png">
          <span>瓦拉格罗文件管理系统</span>
        </div>
        <div class="header-right">
          <router-link replace style="display: none"
                       to="/login?status=0">注册
          </router-link>
          <span style="display: none"></span>
          <router-link replace
                       to="/login?status=1">登录
          </router-link>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="box">
        <div class="title">找回密码</div>
        <ul class="progress">
          <template v-for="(item,index) in statusArr">
            <li :key="index">
              <span :style="'color:'+(item.id===status?'#2D8CF0':'#B8B8B8')">{{item.name}}</span>
              <i :class="{current:(item.id===status)}"
                 class="dot"></i>
            </li>
          </template>
        </ul>
        <div class="form">
          <template v-if="status===1">
            <ul>
              <li>
                <span>手机号：</span>
                <Input @on-blur="phoneBlur"
                       class="flex-1"
                       maxlength="11"
                       placeholder="请输入手机号"
                       type="tel"
                       v-model="phone">
                  <Icon color="#EA4F4D"
                        size="20"
                        slot="suffix"
                        type="ios-alert-outline"
                        v-show="phoneError"/>
                </Input>
                <div class="error"
                     v-show="phoneError">请输入正确的手机号
                </div>
              </li>
              <li>
                <span>验证码：</span>
                <Input class="flex-1"
                       placeholder="请输入验证码"
                       v-model="smsCode">
                </Input>
                <Button :disabled="timeout>0"
                        @click="getResetPwdCode"
                        class="m-l-20">
                  {{timeout===0?'获取验证码':`已发送（${timeout}S）`}}
                </Button>
                <div class="error"
                     v-show="smsCodeError">请输入短信验证码
                </div>
              </li>
            </ul>
            <Button @click="nextStep"
                    class="submitBtn"
                    type="primary">下一步
            </Button>
          </template>
          <template v-if="status===2">
            <ul>
              <li>
                <span>新密码：</span>
                <Input @on-blur="pwdBlur"
                       class="flex-1"
                       maxlength="18"
                       placeholder="请输入6-18位密码"
                       type="password"
                       v-model="pwd">
                  <Icon color="#EA4F4D"
                        size="20"
                        slot="suffix"
                        type="ios-alert-outline"
                        v-show="pwdError"/>
                </Input>
                <div class="error"
                     v-show="pwdError">密码必须为6-18位
                </div>
              </li>
              <li>
                <span>确认密码：</span>
                <Input class="flex-1"
                       maxlength="18"
                       placeholder="请再次输入新密码"
                       type="password"
                       v-model="confirmPwd">
                  <Icon color="#EA4F4D"
                        size="20"
                        slot="suffix"
                        type="ios-alert-outline"
                        v-show="confirmPwdError"/>
                </Input>
                <div class="error"
                     v-show="confirmPwdError">两次密码输入不一致，请重新输入！
                </div>
              </li>
            </ul>
            <Button @click="nextStep"
                    class="submitBtn"
                    type="primary">下一步
            </Button>
          </template>
          <template v-if="status===3">
            <div class="success">
              <img src="../../assets/login/reset_icon_success.png">
              <span>恭喜您，密码设置成功！</span>
            </div>
            <Button @click="navToLogin"
                    class="successBtn"
                    type="primary">直接登录
            </Button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getResetPwdCode, resetPwd} from '../../services/userapi'

export default {
  components: {},
  data () {
    return {
      statusArr: [
        {
          id: 1,
          name: '手机号码验证'
        },
        {
          id: 2,
          name: '密码重置'
        },
        {
          id: 3,
          name: '设置成功'
        },
      ],
      status: 1,
      phone: '',
      phoneError: false,
      smsCode: '',
      smsCode_: '',
      smsCodeError: false,
      timeout: 0,
      pwd: '',
      pwdError: false,
      confirmPwd: '',
      confirmPwdError: false,
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    phoneBlur () {
      let reg = /^1[3456789]\d{9}$/
      this.phoneError = !reg.test(this.phone)
    },
    pwdBlur () {
      let length = this.pwd.length
      this.pwdError = length < 6 || length > 18
    },
    getResetPwdCode () {
      if (this.phone === '' || this.phoneError) return
      let params = {
        phone: this.phone
      }
      getResetPwdCode(params).then(res => {
        if (res.code === 0) {
          if (res.data && res.data.code) {
            this.smsCode_ = res.data.code
          }
          let seconds = 60
          let timer = setInterval(() => {
            --seconds
            this.timeout = seconds
            if (seconds <= 0) {
              clearInterval(timer)
            }
          }, 1000)
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 下一步
    nextStep () {
      switch (this.status) {
        case 1:
          if (this.phone === '' || this.phoneError) return
          if (this.smsCode === '') return this.smsCodeError = true
          // if (this.smsCode !== this.smsCode_) return this.$Message.info('验证码不正确')
          this.status = 2
          break
        case 2:
          if (this.pwd === '' || this.pwdError) return
          if (this.confirmPwd === '' || this.confirmPwd !== this.pwd) return this.confirmPwdError = true
          let params = {
            phone: this.phone,
            smsCode: this.smsCode,
            newPwd: this.pwd
          }
          resetPwd(params).then(res => {
            if (res.code === 0) {
              this.status = 3
            } else {
              if (res.msg) this.$Message.info(res.msg)
            }
          })
          break
        default:
          break
      }
    },
    navToLogin () {
      this.$router.replace('/login?status=1')
    }
  }
}

</script>
<style lang='scss' scoped>
  .main {
    .box {
      width: 60%;
      min-width: 800px;
    }

    .header {
      width: 100%;
      height: 64px;
      background-color: #fff;
      box-shadow: 0px 2px 8px 0px rgba(214, 210, 210, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      top: 0;
      z-index: 1000;

      .box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header-left {
          display: flex;
          align-items: center;

          img {
            width: 60px;
            height: 42px;
            margin-right: 13px;
          }

          span {
            font-size: 20px;
            font-weight: bold;
          }
        }

        .header-right {
          a {
            font-size: 14px;
            color: #666;
            line-height: 16px;
          }

          span {
            line-height: 16px;
            margin: 0 20px;
            border-left: 2px solid #acb2bd;
          }
        }
      }
    }

    .content {
      padding-top: 120px;
      display: flex;
      justify-content: center;

      .box {
        padding: 80px 0;
        box-shadow: 0px 0px 10px 4px rgba(234, 230, 230, 0.5);
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          font-size: 24px;
          font-weight: 600;
        }

        .progress {
          width: 750px;
          margin-top: 60px;
          display: flex;

          li {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;

            &:not(:last-child):before {
              content: " ";
              width: 100%;
              border-bottom: 1px solid #b8b8b8;
              position: absolute;
              top: 39px;
              left: 50%;
              z-index: -1;
            }

            span {
              font-size: 16px;
            }

            .dot {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background-color: #b8b8b8;
              margin-top: 10px;

              &.current {
                background-color: #2d8cf0;
              }
            }
          }
        }

        .form {
          width: 390px;
          margin-top: 30px;

          ul {
            li {
              display: flex;
              align-items: center;
              position: relative;
              margin-top: 30px;

              span {
                width: 70px;
              }

              .error {
                font-size: 12px;
                color: #ea4f4d;
                position: absolute;
                top: 36px;
                left: 70px;
                z-index: 10;
              }
            }
          }

          .submitBtn {
            width: 100%;
            height: 42px;
            border-radius: 4px;
            margin-top: 40px;
            margin-bottom: 20px;
          }

          .success {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 60px;

            img {
              width: 48px;
              height: 48px;
              margin-right: 20px;
            }

            span {
              font-size: 18px;
            }
          }

          .successBtn {
            width: 118px;
            height: 42px;
            display: block;
            margin: 50px auto 0;
          }
        }
      }
    }
  }
</style>
