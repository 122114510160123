import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Base65 from './utils/base64.js'
import { Base64 } from 'js-base64'
import './scss/index.scss'
import './utils/dateUtil'

import VueClipboard from 'vue-clipboard2'
// iview
import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'
import {formatDate, formatFileSize, getFileType} from './utils/utils'
// 图片懒加载
import VueLazyload from 'vue-lazyload'
// 无限滚动加载
import InfiniteLoading from 'vue-infinite-loading'
import {decryptBy, encryptBy} from './utils/desc'

Vue.use(ViewUI)
Vue.use(InfiniteLoading)
Vue.use(VueClipboard)


Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require('./assets/common/home_pic_failure.png'),
  loading: require('./assets/common/home_pic_loading.png'),
  attempt: 1
})


Vue.prototype.$formatFileSize = formatFileSize
Vue.prototype.$getFileType = getFileType
Vue.prototype.$formatDate = formatDate


Vue.prototype.$Base64 = Base65
Vue.prototype.$BaseSix = Base64
Vue.prototype.$descEn = encryptBy
Vue.prototype.$descDe = decryptBy

Vue.config.productionTip = false

export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
