<template>
  <div class="bodyView">
    <slider-menu :menulist="getmenulist"
                 @onsel="onurlsel"></slider-menu>
    <div class="rightView">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import SliderMenu from '@/components/common/SliderMenu'

export default {
  components: {SliderMenu},
  data () {
    return {
      menulist: [
        // {
        //   pid: 0,
        //   id: 1,
        //   child: [
        //     {
        //       id: 1,
        //       name: '最近',
        //       url: '/recent',
        //       routerName: 'recent',
        //       icon: 'icon-rencently',
        //     }
        //   ]
        // },
        {
          pid: 0,
          id: 2,
          child: [
            {
              pid: 2,
              id: 2,
              name: '全部',
              url: '/disk',
              routerName: 'disk',
              icon: 'icon-all',
            },
            {
              pid: 2,
              id: 3,
              name: '文件夹',
              url: '/folder',
              routerName: 'folder',
              icon: 'icon-folder',
            },
            {
              pid: 2,
              id: 4,
              name: '相册',
              url: '/albums/1',
              icon: 'icon-img',
              query: {
                folderType: 2
              }
            },
            {
              pid: 2,
              id: 8,
              name: '图片',
              url: '/albums/3',
              icon: 'icon-img',
              query: {
                folderType: 4
              }
            },
            {
              pid: 2,
              id: 5,
              name: '视频',
              url: '/albums/4',
              icon: 'icon-video',
              query: {
                folderType: 5
              }
            },
            {
              pid: 2,
              id: 6,
              name: '文档',
              url: '/albums/5',
              icon: 'icon-document',
              query: {
                folderType: 3
              }
            },
            // {
            //   pid: 2,
            //   id: 7,
            //   name: '笔记',
            //   url: '',
            //   icon: 'icon-note',
            // },
          ]
        },
        {
          pid: 0,
          id: 3,
          child: [
            // {
            //   pid: 3,
            //   id: 8,
            //   name: '水印',
            //   url: '',
            //   icon: 'icon-wartermark',
            // },
            {
              pid: 3,
              id: 13,
              name: '收藏',
              url: '/collections',
              routerName: 'collections',
              icon:'icon-collection'
            },
            {
              pid: 3,
              id: 9,
              name: '标签',
              url: '/tag',
              routerName: 'tag',
              icon: 'icon-tags',
            },
            {
              pid: 3,
              id: 10,
              name: '回收站',
              url: '/recycle',
              routerName: 'recycle',
              icon: 'icon-trash',
            },
            // {
            //   pid: 3,
            //   id: 11,
            //   name: '收集文件',
            //   url: '',
            //   routerName: 'recycle',
            //   icon: 'icon-collection',
            // },
            // {
            //   pid: 3,
            //   id: 12,
            //   name: '数据统计',
            //   url: '',
            //   icon: 'icon-statistics',
            // }
          ]
        },
        {
          pid: 0,
          id: 4,
          child: [
            {
              id: 13,
              name: '账号管理',
              icon: 'icon-setting',
              child: [
                {
                  pid: 13,
                  id: 1,
                  name: '企业信息',
                  url: '/enterprise/enterpriseinfo',
                  routerName: 'enterpriseinfo',
                  icon: 'icon-company-info',
                }, {
                  pid: 13,
                  id: 2,
                  name: '部门管理',
                  url: '/enterprise/department',
                  routerName: 'department',
                  icon: 'icon-apartment',
                }, {
                  pid: 13,
                  id: 3,
                  name: '成员管理',
                  url: '/enterprise/member',
                  routerName: 'member',
                  icon: 'icon-members',
                },
              ]
            }
          ]
        },
      ]
    }
  },
  computed: {
    getmenulist () {
      let menulist = this.menulist
      return menulist
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    onurlsel (item) {
      let path = item.url
      let location = {path: path}
      if (item.query) {
        location.query = item.query
      }
      this.$router.replace(location)
    }
  }
}
</script>

<style lang="scss" scoped>
  .bodyView {
    padding-top: 64px;
    height: calc(100% - 64px);
  }

  .rightView {
    padding-left: 240px;
    height: calc(100% - 64px);
  }
</style>
