import {get, post, put} from './http'
import config from '../config/constantconfig'

/**
 * 登录
 */
export const login = (account, passwd) => post('tradeapp/user/login/app/ent', {
  phone: account,
  pwd: passwd,
  entId: config.entId,
  appId: config.appId
})

/**
 * 注册
 */
export const register = (params) => post('/netdisk/user/register/plat/ent', params)

/**
 * 发送注册短信
 */
export const getRegisterCode = (params) => post('/netdisk/message/sms/reg', params)

/**
 * 获取登录用户信息
 */
export const getUserInfo = () => get('tradeapp/role', {})

/**
 * 修改用户信息
 */
export const editUserInfo = (params) => put('/netdisk/user', params)

/**
 * 获取修改密码短信
 */
export const getResetPwdCode = (params) => post('tradeapp/message/sms/forget', params)

/**
 * 忘记密码修改
 */
export const resetPwd = (params) => put('tradeapp/user/pwd/forget', params)
