export const isOnLine = process.env.NODE_ENV === 'production' // process.env.NODE_ENV === 'production'

export const urlConfig = {
  dev: 'https://api.fat.laodao.co',
  pro: 'https://apis.box.laodao.co'
}

export const projectName = 'tradeapp'


/**
 * 图片
 */
export const onlineImageOssInfo = {
  region: 'oss-cn-shanghai',
  accessKeyId: 'LTAIufO1A3SGZZ5t',
  accessKeySecret: 'sD83aWrXq89HmNgwpCbrGhDWKGDsia',
  bucket: 'service-center-image',
  severurl: 'service-center-image.oss-cn-shanghai.aliyuncs.com',
  url: 'image.laodao.so'
}

export const underlineImageOssInfo = {
  region: 'oss-cn-qingdao',
  accessKeyId: 'LTAIduij3bFJxU5P',
  accessKeySecret: 'Onih3CvVSCUVsEagRpHJjtpqI7VcxA',
  bucket: 'service-center',
  severurl: 'service-center.oss-cn-qingdao.aliyuncs.com',
  url: 'image.test.laodao.so'
}
/**
 * 视频
 */
export const onlineVideoOssInfo = {
  region: 'oss-cn-shanghai',
  accessKeyId: 'LTAIufO1A3SGZZ5t',
  accessKeySecret: 'sD83aWrXq89HmNgwpCbrGhDWKGDsia',
  bucket: 'service-center-video',
  severurl: 'service-center-video.oss-cn-shanghai.aliyuncs.com',
  url: 'video.laodao.so'
}

export const underlineVideoOssInfo = {
  region: 'oss-cn-qingdao',
  accessKeyId: 'LTAIs3oSm15PNQLQ',
  accessKeySecret: 'CqooofLcKftoaqN5wrgYxOru1DB7nE',
  bucket: 'service-center-video-test',
  severurl: 'service-center-video-test.oss-cn-qingdao.aliyuncs.com',
  url: 'video.test.laodao.co'
}
/**
 * 音频
 */
export const onlineAudioOssInfo = {
  region: 'oss-cn-shanghai',
  accessKeyId: 'LTAIufO1A3SGZZ5t',
  accessKeySecret: 'sD83aWrXq89HmNgwpCbrGhDWKGDsia',
  bucket: 'service-center-audio',
  severurl: 'service-center-audio.oss-cn-shanghai.aliyuncs.com',
  url: 'audio.laodao.so'
}

export const underlineAudioOssInfo = {
  region: 'oss-cn-qingdao',
  accessKeyId: 'LTAIduij3bFJxU5P',
  accessKeySecret: 'Onih3CvVSCUVsEagRpHJjtpqI7VcxA',
  bucket: 'service-center',
  severurl: 'service-center.oss-cn-qingdao.aliyuncs.com',
  url: 'image.test.laodao.so'
}
/**
 * 文件
 */
export const onlineFileOssInfo = {
  region: 'oss-cn-shanghai',
  accessKeyId: 'LTAIufO1A3SGZZ5t',
  accessKeySecret: 'sD83aWrXq89HmNgwpCbrGhDWKGDsia',
  bucket: 'service-center-file',
  severurl: 'service-center-file.oss-cn-shanghai.aliyuncs.com',
  url: 'file.laodao.so'
}

export const underlineFileOssInfo = {
  region: 'oss-cn-qingdao',
  accessKeyId: 'LTAIduij3bFJxU5P',
  accessKeySecret: 'Onih3CvVSCUVsEagRpHJjtpqI7VcxA',
  bucket: 'service-center',
  severurl: 'service-center.oss-cn-qingdao.aliyuncs.com',
  url: 'image.test.laodao.so'
}

export default {
  isOnLine,
  projectName,
  baseUrl: isOnLine ? urlConfig.pro : urlConfig.dev,
  cookieExpires: 10,
  imageOssInfo: isOnLine ? onlineImageOssInfo : underlineImageOssInfo,
  videoOssInfo: isOnLine ? onlineVideoOssInfo : underlineVideoOssInfo,
  audioOssInfo: isOnLine ? onlineAudioOssInfo : underlineAudioOssInfo,
  fileOssInfo: isOnLine ? onlineFileOssInfo : underlineFileOssInfo,
  appKey: isOnLine ? '46c28a8ff9ea4257b8da1b5211278d1c' : '6590ff7910aa4eb8bde92c07e2af0791',  //'9f81ba0bc4264c0495fc87a9a2a2a312',
  requestSource: 1,
  appId: isOnLine ? 37 : 306,
  entId: isOnLine ? 29 : 168
}
