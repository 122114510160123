import {
  addFolder,
  cancelCollect,
  collectFile,
  delFile,
  delFolder,
  delFolderGroup,
  editFileName,
  editFolder,
  getCollectList,
  getEmployeeList,
  getFileLists,
  getFolderDetail,
  getfolderList,
  tansferToFloder
} from '../../services/diskapi'
import {localRead, localSave} from '../../utils/utils'

export default {
  state: {
    pathArr: JSON.parse(localStorage.getItem('pathArr')) || [],
    isShowList: localRead('showMenu') || 0
  },
  getters: {},
  mutations: {
    setPathArr (state, item) {
      state.pathArr.push(item)
      localStorage.setItem('pathArr', JSON.stringify(state.pathArr))
    },
    resetPathArr (state, arr) {
      state.pathArr = arr
      localStorage.setItem('pathArr', JSON.stringify(state.pathArr))
    },
    setMenuShowList (state, type) {
      state.isShowList = type
      localSave('showMenu', type)
    }
  },
  actions: {
    setMenuShowList ({commit}, type) {
      commit('setMenuShowList', type)
    },
    setPathArr ({
                  commit,
                  state
                }, obj) {
      let id = obj.id
      let name = obj.name
      let parentId = obj.parentId
      if (id === 0) {
        commit('resetPathArr', [{
          id: 0,
          name: '全部',
          parentId: 0
        }])
        return
      }
      let index = -1
      let pathArr = state.pathArr
      for (const i in pathArr) {
        if (pathArr[i].id === id) {
          index = parseInt(i)
          break
        }
      }
      if (index !== -1) {
        // 如果存在
        pathArr = pathArr.slice(0, index + 1)
        commit('resetPathArr', pathArr)
      } else {
        if (parentId === pathArr[pathArr.length - 1].id) {
          // 如果父级是最后一个元素
          commit('setPathArr', obj)
        } else {
          commit('setPathArr', obj)
          // 否则去请求接口获取
          // getParentPathList(id, {
          //   fileType: 2
          // }).then(res => {
          //   if (res.code === 0) {
          //     let list = res.data.map(item => {
          //       if (item.folderId === 0) {
          //         return {
          //           id: 0,
          //           name: '全部',
          //           parentId: 0
          //         }
          //       } else {
          //         return {
          //           id: item.folderId,
          //           name: item.name,
          //           parentId: item.parentId
          //         }
          //       }
          //     })
          //     list.push(obj)
          //     commit('resetPathArr', list)
          //   } else {
          //     if (res.msg) vm.$Message.info(res.msg)
          //     vm.$router.replace('/')
          //   }
          // })
        }
      }
    },
    addFolder (store, params) {
      return addFolder(params)
    },
    getfolderList (store, params) {
      return getfolderList(params)
    },
    editFolders (store, {id, params}) {
      return editFolder(id, params)
    },
    editFiles (store, {id, params}) {
      return editFileName(id, params)
    },
    delFolder (store, params) {
      return delFolder(params)
    },
    delFile (store, params) {
      return delFile(params)
    },
    delFolderGroup (store, params) {
      return delFolderGroup(params)
    },
    getFolderDetail (store, id) {
      return getFolderDetail(id)
    },
    tansferToFloder (store, params) {
      return tansferToFloder(params)
    },
    getCollectList (store, params) {
      return getCollectList(params)
    },
    collectFile (store, params) {
      return collectFile(params)
    },
    cancelCollect (store, params) {
      return cancelCollect(params)
    },
    getEmployeeList (store, params) {
      return getEmployeeList(params)
    },
    getFileList (store, params) {
      return getFileLists(params)
    },
  }
}
