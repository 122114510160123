<template>
  <div :class="isOpen?'open':'fold'"
       class="uploadTask"
       v-if="visible">
    <div class="uploadTask-window">
      <Icon @click.stop="isOpen=false"
            size="20"
            type="ios-arrow-down"
            v-show="isOpen"/>
      <Icon @click.stop="isOpen=true"
            size="20"
            type="ios-arrow-up"
            v-show="!isOpen"/>
      <Icon @click.stop="visible=false"
            size="20"
            type="md-close"/>
    </div>
    <div class="uploadTask-con open"
         v-show="isOpen">
      <div class="uploadTask-title flex a-center">
        <template v-if="taskStatus===2">
          <img class="rotate"
               src="../../assets/common/upload_icon_uploading_big.png">
          <div>
            <div class="uploadTask-status">文件上传中…</div>
            <div class="uploadTask-count">{{waitCount}}项待上传/共{{taskList.length}}项任务</div>
          </div>
          <div @click.stop="cancelAllUpload"
               class="uploadTask-operation">取消全部
          </div>
        </template>
        <template v-if="taskStatus===3">
          <img src="../../assets/common/upload_icon_success_big.png">
          <div>
            <div class="uploadTask-status">文件上传成功！</div>
            <div class="uploadTask-count">{{successCount}}项任务上传成功</div>
          </div>
        </template>
        <template v-if="taskStatus===4">
          <img src="../../assets/common/upload_icon_sb_big.png">
          <div>
            <div class="uploadTask-status">文件上传失败！</div>
            <div class="uploadTask-count">{{failCount}}项任务上传失败</div>
          </div>
          <div @click.stop="againAllUpload"
               class="uploadTask-operation">重试全部
          </div>
        </template>
        <template v-if="taskStatus===5">
          <img src="../../assets/common/upload_icon_sb_big.png">
          <div>
            <div class="uploadTask-status">文件取消上传</div>
            <div class="uploadTask-count">{{cancelCount}}项任务已取消</div>
          </div>
          <div @click.stop="againAllUpload"
               class="uploadTask-operation">重试全部
          </div>
        </template>
      </div>
      <div class="uploadTask-list noscroll">
        <Table :columns="columns"
               :data="taskList"
               :show-header="false"
               disabled-hover
               ref="table"
               style="width:100%">
          <template slot="name"
                    slot-scope="{ row }">
            <div class="uploadTask-item-name">
              <FileIcon :file="row"
                        class="m-r-10"/>
              <span>{{row.name}}</span>
            </div>
          </template>
          <template slot="progress"
                    slot-scope="{ row }">
            <div class="uploadTask-item-progress">
              <span v-if="row.status===1">准备上传</span>
              <span v-if="row.status===2&&!row.progress">文件解析中</span>
              <span style="color:#2D8CF0"
                    v-if="row.status===2&&row.progress">{{row.progress}}%</span>
              <span v-if="row.status===3">上传成功</span>
              <span v-if="row.status===4">上传失败</span>
              <span v-if="row.status===5">已取消</span>
            </div>
          </template>
          <template slot="status"
                    slot-scope="{ row,index }">
            <div class="uploadTask-item-status">
              <img @click.stop="cancelUpload(index)"
                   class="cursor-pointer"
                   src="../../assets/common/upload_icon_cancle.png"
                   v-if="row.status===2">
              <img src="../../assets/common/upload_icon_success_big.png"
                   v-if="row.status===3">
              <img @click.stop="againUpload(index)"
                   class="cursor-pointer"
                   src="../../assets/common/upload_defeat_icon_reupdate.png"
                   v-if="row.status===4">
              <img @click.stop="againUpload(index)"
                   class="cursor-pointer"
                   src="../../assets/common/upload_defeat_icon_reupdate.png"
                   v-if="row.status===5">
            </div>
          </template>
        </Table>
      </div>
    </div>
    <div class="uploadTask-con fold"
         v-show="!isOpen">
      <div class="uploadTask-title">
        <template v-if="taskStatus===2">
          <div class="flex a-center">
            <img class="rotate"
                 src="../../assets/common/upload_icon_uploading_big.png">
            <div class="uploadTask-status">文件上传中…</div>
          </div>
          <div class="uploadTask-count">{{waitCount}}项待上传/共{{taskList.length}}项任务</div>
        </template>
        <template v-if="taskStatus===3">
          <div class="flex a-center">
            <img src="../../assets/common/upload_icon_success_big.png">
            <div class="uploadTask-status">文件上传成功！</div>
          </div>
          <div class="uploadTask-count">{{successCount}}项任务上传成功</div>
        </template>
        <template v-if="taskStatus===4">
          <div class="flex a-center">
            <img src="../../assets/common/upload_icon_sb_big.png">
            <div class="uploadTask-status">文件上传失败！</div>
          </div>
          <div class="uploadTask-count">{{failCount}}项任务上传失败</div>
        </template>
        <template v-if="taskStatus===5">
          <div class="flex a-center">
            <img src="../../assets/common/upload_icon_sb_big.png">
            <div class="uploadTask-status">文件取消上传</div>
          </div>
          <div class="uploadTask-count">{{cancelCount}}项任务已取消</div>
        </template>
        <div class="uploadTask-operation">取消全部</div>
      </div>
    </div>
  </div>
</template>

<script>
import FileIcon from '../../components/common/FileIcon'
import {uploadFile} from '../../services/diskapi'
import {getUploderObj} from '../../utils/ossupload'
import config from '../../config/constantconfig'
import bus from '../../utils/bus'

const uuidv1 = require('uuid/v1')
export default {
  components: {FileIcon},
  data () {
    return {
      visible: false,
      isOpen: true,
      limitUploadCount: 1,
      uploadCount: 0,
      taskList: [],
      taskStatus: 0,                        //2正在上传 3上传成功 4上传失败 5取消
      waitCount: 0,
      successCount: 0,
      failCount: 0,
      cancelCount: 0,
      columns: [
        {
          key: 'name',
          width: 310,
          ellipsis: true,
          slot: 'name'
        },
        {
          key: 'size',
          width: 100,
          render: (h, params) => {
            let size = params.row.size
            let text = this.$formatFileSize(size)
            return h('span', text)
          }
        },
        {
          key: 'folderName',
          width: 130,
          ellipsis: true,
        },
        {
          slot: 'progress',
          width: 80
        },
        {
          slot: 'status',
          width: 20
        }
      ],
      fun: null,
      closefun: null
    }
  },
  computed: {},
  mounted () {
    this.init()
  },
  destroy () {
    bus.$off('openUploader', this.fun)
    bus.$off('openClose', this.closefun)
  },
  methods: {
    init () {
      bus.$off('openUploader')
      this.fun = (query) => {
        let filelist = Array.from(query.files)
        let folderId = query.folderId
        let folderName = query.folderName
        let fileType = query.fileType
        let folderGpId = query.folderGpId
        this.createTask(filelist, folderId, folderName, fileType, folderGpId)
        bus.$emit('openTypeClose')
      }
      bus.$on('openUploader', this.fun)
      this.closefun = () => {
        if (this.visible) this.visible = false
      }
      bus.$on('openClose', this.closefun)
    },
    // 添加table tr鼠标移入移出事件
    addTableMouseEvent () {
      this.$nextTick(() => {
        let arr = this.$refs.table.$children[0].$children
        arr.forEach(item => {
          item.$el.onmouseover = () => {
            item.row.mouseStatus = 1
          }
          item.$el.onmouseout = () => {
            item.row.mouseStatus = 0
          }
        })
      })
    },
    // 创建任务
    async createTask (filelist, folderId, folderName, fileType, folderGpId) {
      let arr = await this.getArr(filelist, folderId, folderName, fileType, folderGpId)
      this.taskList = arr.concat(this.taskList)
      // this.addTableMouseEvent()
      if (!this.visible) this.visible = true
      this.taskStatus = 1
      this.updateTask()
    },
    getArr (filelist, folderId, folderName, fileType, folderGpId) {
      return new Promise(async (resolve, reject) => {
        let arr = []
        for (let i = 0; i < filelist.length; i++) {
          let item = filelist[i]
          let uploader = this.newUploderObj(item)
          let fileTypeName = this.$getFileType(item.name)
          let width = 0
          let height = 0
          let realType = 50

          if (fileTypeName === 'image') {
            let obj = await this.getImgInfo(item)
            width = obj.width
            height = obj.height
            realType = 1
          } else if (fileTypeName === 'video') {
            realType = 2
          } else if (fileTypeName === 'word') {
            realType = 3
          } else if (fileTypeName === 'excel') {
            realType = 4
          } else if (fileTypeName === 'ppt') {
            realType = 5
          } else if (fileTypeName === 'pdf') {
            realType = 6
          } else if (fileTypeName === 'audio') {
            realType = 20
          }

          arr.push({
            fileObj: item,
            folderId,
            folderGpId,
            folderName,
            uploader,
            status: 1,
            progress: 0,
            mouseStatus: 0,
            name: item.name,
            size: item.size,
            webkitRelativePath: item.webkitRelativePath,
            objType: 1,
            fileType: realType,
            width: width || 0,
            height: height || 0
          })
        }
        resolve(arr)
      })
    },
    // createTask (filelist, folderId, folderName) {
    //   getTaskLogId({operateType: 1}).then(res => {
    //     if (res.code === 0) {
    //       let logId = res.data.logId
    //       let arr = []
    //       filelist.forEach(item => {
    //         let uploader = this.newUploderObj(item)
    //         arr.push({
    //           fileObj: item,
    //           folderId,
    //           folderName,
    //           uploader,
    //           status: 1,
    //           progress: 0,
    //           mouseStatus: 0,
    //           name: item.name,
    //           size: item.size,
    //           webkitRelativePath: item.webkitRelativePath,
    //           objType: 1,
    //           logId
    //         })
    //       })
    //       this.taskList = arr.concat(this.taskList)
    //       // this.addTableMouseEvent()
    //       if (!this.visible) this.visible = true
    //       this.updateTask()
    //     } else {
    //       if (res.msg) this.$Message.info(res.msg)
    //     }
    //   })
    // },

    getImgInfo (item) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(item)
        reader.onload = function () {
          const img = new Image()
          img.src = reader.result
          let width = 0
          let height = 0
          if (img.complete) {
            width = img.width
            height = img.height
            resolve({
              width,
              height
            })
          } else {
            img.onload = function () {
              width = img.width
              height = img.height
              resolve({
                width,
                height
              })
            }
          }
        }
      })
    },
    // 获取上传进度
    callback (progress, checkpoint, item) {
      item.progress = Number.parseInt(progress * 100) < 1 ? 1 : Number.parseInt(progress * 100)
      item.checkpoint = checkpoint
      if (item.progress === 100) {
        item.progress = 0
      }
    },
    // 获取文件后缀名
    getFileSuffix (name) {
      let resultstr = ''
      let index = name.lastIndexOf('.')
      if (index > 0) {
        resultstr = name.substring(index)
      }
      return resultstr
    },
    // 创建oss对象
    newUploderObj (file) {
      let uuid = uuidv1()
      let name = this.getFileSuffix(file.name)
      let fileType = this.$getFileType(file.name)
      let uploadPath
      switch (fileType) {
        case 'image':
          uploadPath = (config.isOnLine ? '' : 'image/') + config.projectName + '/common/desc/'
          break
        case 'video':
          uploadPath = config.projectName + '/common/desc/'
          break
        case 'audio':
          uploadPath = (config.isOnLine ? '' : 'audio/') + config.projectName + '/common/desc/'
          break
        case 'word':
          uploadPath = (config.isOnLine ? '' : 'file/') + config.projectName + '/common/word/'
          break
        case 'ppt':
          uploadPath = (config.isOnLine ? '' : 'file/') + config.projectName + '/common/ppt/'
          break
        case 'pdf':
          uploadPath = (config.isOnLine ? '' : 'file/') + config.projectName + '/common/pdf/'
          break
        case 'excel':
          uploadPath = (config.isOnLine ? '' : 'file/') + config.projectName + '/common/excel/'
          break
        default:
          uploadPath = (config.isOnLine ? '' : 'file/') + config.projectName + '/common/common/'
          break
      }
      let uploader = getUploderObj(fileType, uploadPath + uuid + name, file)
      return uploader
    },
    // 上传
    upload (item) {
      item.uploader.multipartUpload(item.checkpoint, (progress, checkpoint) => {
        this.callback(progress, checkpoint, item)
      }).then(res => {
        console.log(res)
        item.uploader = null
        if (res.url) {
          item.url = res.url
          this.uploadFile(item)
        } else {
          this.updateTask()
        }
      }, (err) => {
        console.log(err)
        item.uploader = null
        item.status = 4
        this.uploadCount--
        this.updateTask()
      })
    },
    // 接口上传
    uploadFile (item) {
      this.uploadCount--
      var flieArr = item.name.split('.')
      let suffix = flieArr[flieArr.length - 1]
      let params = {
        folderId: item.folderId,
        permissionType: 1,
        folderType: 1,
        contentList: [{
          contentId: 0,
          name: item.name,
          displayName: item.name,
          suffix,
          size: item.size,
          url: item.url,
          path: item.webkitRelativePath || item.name || '',
          type: item.fileType,
          width: item.width,
          height: item.height
        }]
      }
      uploadFile(params).then(res => {
        if (res.code === 0) {
          item.status = 3
          let folderId
          if (this.$route.params.code) {
            let route = JSON.parse(this.$Base64.decode(this.$route.params.code))
            folderId = route.id
          } else {
            folderId = 0
          }
          if (folderId === item.folderId) {
            bus.$emit('uploadSuccess')
          }
        } else {
          item.status = 4
        }
        this.updateTask()
      }).catch(err => {
        item.status = 4
        this.updateTask()
      })
    },
    // 更新任务队列
    updateTask () {
      let waitCount = 0
      let successCount = 0
      let failCount = 0
      let cancelCount = 0
      for (const item of this.taskList) {
        switch (item.status) {
          // 未开始
          case 1:
            if (!item.uploader) {
              item.uploader = this.newUploderObj(item.fileObj)
            }
            if (this.uploadCount < this.limitUploadCount) {
              item.status = 2
              this.taskStatus = 2
              this.uploadCount++
              if (item.url) {
                this.uploadFile(item)
              } else {
                this.upload(item)
              }
            } else {
              waitCount++
            }
            break
          // 正在上传
          case 2:
            break
          // 成功
          case 3:
            successCount++
            break
          // 失败
          case 4:
            failCount++
            break
          // 取消
          case 5:
            cancelCount++
            break
        }
      }
      if (failCount + successCount + cancelCount === this.taskList.length) {
        if (cancelCount > 0) {
          this.taskStatus = 5
        } else {
          this.taskStatus = 3
        }

      }
      // if (this.uploadCount > 0) {
      //   this.taskStatus = 2
      // } else if (failCount > 0) {
      //   this.taskStatus = 4
      // } else if (cancelCount > 0) {
      //   this.taskStatus = 5
      // } else if (successCount > 0) {
      //   this.taskStatus = 3
      // }
      this.waitCount = waitCount
      this.successCount = successCount
      this.failCount = failCount
      this.cancelCount = cancelCount
    },
    // 失败icon
    failIcon (row) {
      let url = ''
      if (row.mouseStatus === 0) {
        url = require('../../assets/common/upload_icon_sb_big.png')
      } else {
        url = require('../../assets/common/upload_defeat_icon_reupdate.png')
      }
      return url
    },
    // 取消上传
    cancelUpload (index) {
      let item = this.taskList[index]
      console.log('this.taskList=>', this.taskList, 'item:=>', item)
      item.uploader.cancel()
      item.uploader = null
      item.status = 5
      this.uploadCount--
    },
    // 重新上传
    againUpload (index) {
      let item = this.taskList[index]
      item.status = 1
      this.taskStatus = 1
      this.updateTask()
    },
    // 取消全部
    cancelAllUpload () {
      this.taskList.forEach(item => {
        if (item.status === 1 || item.status === 2) {
          item.uploader.cancel()
          item.uploader = null
          item.status = 5
        }
      })
      this.uploadCount = 0
      this.taskStatus = 5
      this.updateTask()
    },
    // 重试全部
    againAllUpload () {
      this.taskList.forEach(item => {
        if (item.status === 4 || item.status === 5) {
          item.status = 1
        }
      })
      this.updateTask()
    },

  }
}

</script>
<style lang='scss' scoped>
  .uploadTask {
    width: 680px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #dedede;
    transition: height 0.3s ease-in-out;
    position: fixed;
    right: 30px;
    bottom: 20px;
    z-index: 1000;

    &.open {
      height: 420px;
    }

    &.fold {
      height: 84px;
    }

    .uploadTask-window {
      position: absolute;
      top: 15px;
      right: 18px;
      z-index: 1001;
      display: flex;

      ::v-deep i {
        cursor: pointer;
      }
    }

    .uploadTask-con {
      .uploadTask-title {
        padding: 60px 20px;
        position: relative;

        img.rotate {
          -webkit-transform: rotate(-360deg);
          animation: rotation 2s linear infinite;
          -moz-animation: rotation 2s linear infinite;
          -webkit-animation: rotation 2s linear infinite;
          -o-animation: rotation 2s linear infinite;
        }

        @-webkit-keyframes rotation {
          from {
            -webkit-transform: rotate(0deg);
          }
          to {
            -webkit-transform: rotate(-360deg);
          }
        }

        .uploadTask-count {
          font-size: 14px;
          color: #999;
        }

        .uploadTask-operation {
          font-size: 14px;
          color: #999;
          cursor: pointer;
          position: absolute;
          right: 20px;
          bottom: 14px;
        }
      }

      .uploadTask-list {
        height: 240px;
        padding: 0 14px;
        overflow: auto;
        overflow-x: hidden;

        ::v-deep .ivu-table-cell {
          padding: 0;
        }

        .uploadTask-item-name {
          display: flex;
          align-items: center;
        }

        .uploadTask-item-progress {
          span {
            color: #666;
          }
        }

        .uploadTask-item-status {
          display: flex;
          align-items: center;

          img {
            width: 20px;
            height: 20px;
          }
        }
      }

      &.open {
        .uploadTask-title {
          padding: 60px 20px;

          img {
            width: 44px;
            height: 44px;
            margin-right: 13px;
          }

          .uploadTask-status {
            font-size: 24px;
          }
        }
      }

      &.fold {
        .uploadTask-title {
          padding: 14px 20px;

          img {
            width: 18px;
            height: 18px;
            margin-right: 10px;
          }

          .uploadTask-status {
            font-size: 16px;
          }

          .uploadTask-count {
            margin-top: 12px;
          }
        }
      }
    }
  }
</style>
