import {decryptBy, encryptBy} from './desc'

const Base64 = {
  //加密
  encode (str) {
    return encryptBy(str)
    // return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
    //   function toSolidBytes(match, p1) {
    //     return String.fromCharCode('0x' + p1);
    //   })).replaceAll('/','%');
  },
  //解密
  decode (str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decryptBy(str)
    // return decodeURIComponent(atob(str.replaceAll('%','/')).split('').map(function (c) {
    //   return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    // }).join(''));
  }
}
export default Base64
