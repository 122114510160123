import config from '../config/constantconfig'
let OSS = require('ali-oss')
class Uploader extends OSS {
  constructor(fileType, fileName, file) {
    let ossTypeConfig
    switch (fileType) {
      case 'image':
        ossTypeConfig = config.imageOssInfo
        break;
      case 'video':
        ossTypeConfig = config.videoOssInfo
        break;
      case 'audio':
        ossTypeConfig = config.audioOssInfo
        break;
      default:
        ossTypeConfig = config.fileOssInfo
        break;
    }
    let ossConfig = {
      region: ossTypeConfig.region,
      accessKeyId: ossTypeConfig.accessKeyId,
      accessKeySecret: ossTypeConfig.accessKeySecret,
      bucket: ossTypeConfig.bucket
    }
    super(ossConfig)
    this.ossTypeConfig = ossTypeConfig
    this.fileName = fileName
    this.file = file
  }
  async multipartUpload(checkpoint, callback) {
    try {
      let uploadConfig = {
        progress: callback
      }
      if (checkpoint) uploadConfig.checkpoint = checkpoint
      let result = await super.multipartUpload(this.fileName, this.file, uploadConfig)
      let resultinfo = {
        size: result.res.size,
        rt: result.res.rt,
        url: 'https://' + this.ossTypeConfig.url + '/' + result.name
      }
      return resultinfo
    } catch (err) {
      return err
    }
  }
}

export const getUploderObj = (fileType, fileName, file) => {
  return new Uploader(fileType, fileName, file)
}
