<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="page-style">
    <HeaderComponent class="h-60"></HeaderComponent>
    <ContentComponent class="m-h-full"></ContentComponent>
    <DiskUploadTask></DiskUploadTask>
  </div>
</template>

<script>
import HeaderComponent from '@/components/common/HeaderComponent'
import ContentComponent from '@/components/common/ContentComponent'
import DiskUploadTask from '@/components/disk/DiskUploadTask'
export default {
  name: 'home',
  components: { HeaderComponent, ContentComponent, DiskUploadTask },
  data () {
    return {

    }
  }
}

</script>
<style lang='scss' scoped>
.page-style {
  width: 100%;
  height: 100%;
}

.m-h-full {
  height: calc(100% - 64px);
}
</style>