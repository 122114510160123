/** axios封装
 *  请求拦截、相应拦截、错误统一处理
 */
import content from '../main.js'
// 引入axios
import axios from 'axios'
// get参数拼接
import store from '../store'
import constantconfig from '../config/constantconfig'
// 环境的切换
axios.defaults.baseURL = constantconfig.baseUrl

// 请求超时时间
axios.defaults.timeout = 10000
// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
// 请求拦截器
axios.interceptors.request.use(
  config => {
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    const token = store.state.user.token
    token && (config.headers.Authorization = token)
    return config
  },
  error => {
    return Promise.error(error)
  })
// 响应拦截器
axios.interceptors.response.use(
  response => {
    if (response.status === 200) {
      let data = response.data
      let code = data.code
      if (code === 11001) { // token失效
        content.$store.dispatch('handleLogOut')
        content.$router.replace('/login')
      } else if (code === 106999) {
        // content.$store.dispatch('getUserInfo')
      }
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  // 服务器状态码不是200的情况
  error => {
    if (error.response.status) {
      switch (error.response.status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 401:
          content.$router.replace({
            path: '/login',
            // query: {redirect: content.$router.currentRoute.fullPath}
          })
          break
          // 403 token过期
          // 登录过期对用户进行提示
          // 清除本地token和清空vuex中token对象
          // 跳转登录页面
        case 403:
          content.$Message.info('登录过期，请重新登录')
          // 清除token
          localStorage.removeItem('token')
          // store.commit('loginSuccess', null)
          // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
          // setTimeout(() => {
          //   content.$router.replace({
          //     path: '/login',
          //     query: {
          //       redirect: content.$router.currentRoute.fullPath
          //     }
          //   })
          // }, 1000)
          break
          // 404请求不存在
        case 404:
          content.$Message.info('网络请求不存在')
          break
          // 其他错误，直接抛出错误提示
        default:
          content.$Message.info(error.response.data.message)
      }
      return Promise.reject(error.response)
    }
  }
)

/**
 *  get方法，对应get请求
 *  @param {String} url [请求的url地址]
 *  @param {Object} params [请求时携带的参数]
 */
export function get(url, params = {}) {
  let token = store.state.user.token
  if (token) {
    params.token = token
  }
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params,
      headers: {
        'requestSource': constantconfig.requestSource,
        'appKey': constantconfig.appKey,
        'Access-Control-Allow-Credentials': 'true'
      }
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params = {}) {
  let token = store.state.user.token
  return new Promise((resolve, reject) => {
    let config = {
      headers: {
        'requestSource': constantconfig.requestSource,
        'appKey': constantconfig.appKey
      }
    }
    if (token) {
      config.params = {
        token: token
      }
    }

    axios.post(url, params, config).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}

/**
 * put方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function put(url, params = {}) {
  let token = store.state.user.token
  return new Promise((resolve, reject) => {
    let config = {
      headers: {
        'requestSource': constantconfig.requestSource,
        'appKey': constantconfig.appKey
      }
    }
    if (token) {
      config.params = {
        token: token
      }
    }
    axios.put(url, params, config).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}

/**
 * patch方法，对应patch请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function patch(url, params = {}) {
  let token = store.state.user.token
  return new Promise((resolve, reject) => {
    let config = {
      headers: {
        'requestSource': constantconfig.requestSource,
        'appKey': constantconfig.appKey
      }
    }
    if (token) {
      config.params = {
        token: token
      }
    }
    axios.patch(url, params, config).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}

export function del(url, params = {}) {
  let token = store.state.user.token
  if (token) {
    params.token = token
  }
  return new Promise((resolve, reject) => {
    axios.delete(url, {
      params: params,
      headers: {
        'requestSource': constantconfig.requestSource,
        'appKey': constantconfig.appKey
      }
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}