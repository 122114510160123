import {
  getUserInfo,
  login
} from '@/services/userapi'
import {
  getToken,
  setToken
} from '@/utils/tokenutils'

export default {
  state: {
    userId: '',
    phone: '',
    userName: '',
    avatarImgPath: '',
    hasGetInfo: false,
    token: getToken(),
    roleType: 0
  },
  getters: {},
  mutations: {
    setUserId(state, id) {
      state.userId = id
    },
    setPhone(state, phone) {
      state.phone = phone
    },
    setUserName(state, name) {
      state.userName = name
    },
    setAvatar(state, avatarPath) {
      state.avatarImgPath = avatarPath
    },
    setHasGetInfo(state, status) {
      state.hasGetInfo = status
    },
    setToken(state, token) {
      state.token = token
      setToken(token)
    },
    setRoleType(state, roleType) {
      state.roleType = roleType
    }
  },
  actions: {
    handleLogin({
      commit
    }, {
      account,
      passwd
    }) {
      return new Promise((resolve, reject) => {
        login(account, passwd).then(res => {
          if (res.code === 0) {
            const data = res.data
            commit('setToken', data.token)
            resolve(data)
          } else {
            reject(res.msg)
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    getUserInfo({
      commit
    }) {
      return new Promise((resolve, reject) => {
        try {
          getUserInfo().then(res => {
            if (res.code === 0) {
              const data = res.data
              commit('setUserId', data.id)
              commit('setAvatar', data.logo)
              commit('setPhone', data.phone)
              commit('setUserName', data.name)
              commit('setHasGetInfo', true)
              commit('setRoleType', data.roleType)
              resolve(data)
            } else {
              reject()
            }
          }, error => {
            reject(error)
          })
        } catch (e) {
          reject(e)
        }
      })
    },
    /**
     * 退出登录
     * @param commit
     */
    handleLogOut({
      commit
    }) {
      commit('setToken', '')
      commit('setHasGetInfo', false)
    },
  }
}
