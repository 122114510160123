<template>
  <div class="sliderMenu">
    <div :key="index"
         v-for="(item,index) in menulist">
      <ul>
        <li :class="{'current':ischildCheck(items)}"
            :key="indexs"
            @click="onChildItemClick(items)"
            v-for="(items,indexs) in item.child">
          <div class="sliderMenu-item">
            <Icon :custom="'i-icon '+items.icon"
                  size="16"/>
            <span>{{items.name}}</span>
          </div>
          <ul>
            <li :class="{'current':ischildCheck(itemss)}"
                :key="indexss"
                @click="onChildItemClick(itemss)"
                v-for="(itemss,indexss) in items.child">
              <div class="sliderMenu-item">
                <Icon :custom="'i-icon '+itemss.icon"
                      size="16"/>
                <span>{{itemss.name}}</span>
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <div class="sliderMenu-line"
           v-if="(index+1)!==menulist.length"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menulist: Array
  },
  watch: {
    getChange: {
      handler (newValue) {
        let alllist = this.getAllList()
        let list = alllist.filter(res => {
          return res.url === newValue.path
        })
        if (list && list.length) {
          let item = list[0]
          this.pid = item.pid
          this.cid = item.id
        }
      },
      deep: true,
      immediate: true
    },
  },
  computed: {
    getChange () {
      let path = this.$route.path
      let menulist = this.menulist
      return {
        path,
        menulist
      }
    }
  },
  data () {
    return {
      pid: 0,
      cid: 0,
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
    },
    getAllList () {
      let list = []
      this.menulist.map(res => {
        list.push(res)
        if (res.child && res.child.length) {
          this.formatlist(list, res.child)
        }
        if (res.urlchild && res.urlchild.length) {
          res.urlchild.map(cchild => {
            list.push(cchild)
          })
        }
      })
      return list
    },
    formatlist (list, child) {
      child.map(res => {
        list.push(res)
        if (res.child && res.child.length) {
          this.formatlist(list, res.child)
        }
        if (res.urlchild && res.urlchild.length) {
          res.urlchild.map(cchild => {
            list.push(cchild)
          })
        }
      })
    },
    isparentCheck (item) {
      return this.cid === item.id && item.pid === 0
    },
    ischildCheck (item) {
      return this.cid === item.id && this.pid === item.pid
    },
    onmenuclick (item) {
      if (item.url && this.$route.path !== item.url) {
        this.$emit('onsel', item)
      }
    },
    onChildItemClick (citem) {
      if (citem.url && this.$route.path !== citem.url) {
        this.$emit('onsel', citem)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .sliderMenu {
    width: 240px;
    padding: 10px 2px;
    height: calc(100% - 64px);
    overflow-y: auto;
    background-color: #f7f7f7;
    position: fixed;
    left: 0;
    top: 64px;

    li {
      padding-left: 30px;
      color: #666;

      &.current {
        // padding-left: 26px;
        // border-left: 4px solid #2d8cf0;
        .sliderMenu-item {
          color: #2d8cf0;
        }
      }

      .sliderMenu-item {
        height: 48px;
        display: flex;
        align-items: center;
        cursor: pointer;

        ::v-deep .ivu-icon {
          margin-right: 10px;
        }

        span {
          font-size: 16px;
        }
      }
    }

    .sliderMenu-line {
      width: 180px;
      height: 2px;
      background-color: #d8d8d8;
      margin: 10px auto;
    }
  }
</style>
