import {del, get, post, put} from './http'

/**
 * 上传文件
 */
export const uploadFile = (params) => post('tradeapp/document/upload', params)

/**
 * 获取指定文件夹下的文件夹列表
 */
export const getFolderList = (params) => get('tradeapp/folder/page/ent/list', params)

/**
 * 获取指定文件夹下的文件列表
 */
export const getFileList = (params) => get('tradeapp/document/ent/list', params)
/**
 * 删除文件素材
 * @param params
 */
export const delFile = (params) => del('tradeapp/document', params)
/**
 * 获取文件列表
 * @param params
 */
export const getFileLists = (params) => get('tradeapp/document/ent/list', params)

/**
 * 修改文件夹名称
 */
export const editFolderName = (id, params) => put('tradeapp/folder/' + id, params)

/**
 * 修改文件名称
 */
export const editFileName = (id, params) => put('tradeapp/document/' + id, params)

/**
 * 批量删除文件夹与文件进回收站
 */
export const deleteFolderAndFileToRecycle = (params) => del('tradeapp/document/recycle', params)

/**
 * 批量新建文件夹
 */
export const createFolder = (params) => post('tradeapp/folder', params)

/**
 * 获取文件或文件夹父级路径列表
 */
export const getParentPathList = (id, params) => get('/netdisk/folder/manager/parent/list/' + id, params)

/**
 * 创建操作日志并获取任务id信息
 */
export const getTaskLogId = (params) => post('/netdisk/log/manager', params)

/**
 * 获取回收站列表
 */
export const getRecycleList = (params) => get('tradeapp/recycle/list', params)

/**
 * 批量还原回收站中的记录
 */
export const restoreRecycle = (params) => put('tradeapp/recycle/reduction', params)

/**
 * 删除回收站
 */
export const deleteRecycle = (params) => del('tradeapp/recycle', params)

/**
 * 清空回收站
 */
export const emptyRecycle = () => del('tradeapp/recycle/empty')

/**
 * 获取最近列表
 */
export const getRecentList = (params) => get('tradeapp/log/ent/list', params)

/**
 * 批量移动文件文件夹
 */
export const moveFolderAndFile = (params) => put('tradeapp/document/move', params)

/**
 * 新建分组
 */
export const createGroup = (params) => post('tradeapp/folder', params)

/**
 * 修改分组
 */
export const editGroup = (id, params) => put('tradeapp/folder/' + id, params)

/**
 * 批量删除分组
 */
export const deleteGroup = (params) => del('tradeapp/folder', params)

/**
 * 获取分组列表
 */
export const getGroupList = (params) => get('tradeapp/folder/page/ent/list', params)

/**
 * 获取分组下的文件信息列表
 */
export const getGroupFileList = (params) => get('/netdisk/file/group/list', params)

/**
 * 批量修改分组排序
 */
export const editGroupSort = (params) => put('tradeapp/folder/sort', params)

/**
 * 对象关联分组接口
 */
export const objRelevanceGroup = (params) => post('/netdisk/group/obj', params)

/**
 * 获取分组下的标签列表
 */
export const getTagList = (params) => get('tradeapp/label/list', params)

/**
 * 新建标签
 */
export const createTag = (params) => post('tradeapp/label', params)

/**
 * 修改标签
 */
export const editTag = (id, params) => put('tradeapp/label/' + id, params)

/**
 * 批量删除标签
 */
export const deleteTag = (params) => del('tradeapp/label', params)

/**
 * 合并标签
 */
export const mergeTag = (params) => put('tradeapp/label/relation/merge', params)

/**
 * 批量关联标签
 */
export const relevanceTag = (params) => post('tradeapp/label/relation', params)

/**
 * 批量取消关联标签
 */
export const cancleRelevanceTag = (params) => del('tradeapp/label/relation', params)

/**
 * 获取文件或文件夹对应的标签信息
 */
export const getFolderAndFileTag = (params) => get('tradeapp/label/relation/list', params)

/**
 * 获取标签关联的文件夹列表
 */
export const getTagFolderList = (params) => get('tradeapp/folder/label/ent/list', params)

/**
 * 获取标签关联的文件列表
 */
export const getTagFileList = (params) => get('/tradeapp/document/label/ent/list', params)

/**
 * 创建分享链接
 */
export const createShareUrl = (params) => post('/netdisk/share', params)

/**
 * 修改分享链接信息
 */
export const editShareUrlInfo = (params) => put('/netdisk/share', params)

/**
 * 获取分享链接信息
 */
export const getShareUrlInfo = (id) => get('/netdisk/share/' + id)


/************************ 文件夹 ***************************/

/**
 * 添加文件夹
 * @param params
 */
export const addFolder = (params) => post('tradeapp/folder', params)

/**
 * 文件夹列表
 * @param params
 */
export const getfolderList = (params) => get('tradeapp/folder/ent/list', params)

/**
 * 编辑文件夹
 * @param id
 * @param params
 */
export const editFolder = (id, params) => put('tradeapp/folder/' + id, params)

/**
 * 批量删除文件夹
 * @param params
 */
export const delFolder = (params) => del('tradeapp/folder', params)

/**
 * 删除文件夹分组
 * @param params
 */
export const delFolderGroup = (params) => del('/tradeapp/folder/relation', params)

/**
 * 获取文件夹详情
 * @param id
 */
export const getFolderDetail = (id) => get('tradeapp/folder/' + id, {})

/**
 * 迁移文件到文件夹
 * @param params
 */
export const tansferToFloder = (params) => put('tradeapp/document/move', params)

/************************ 收藏 ***************************/

/**
 * 获取收藏列表
 */
export const getCollectList = (params) => get('tradeapp/user/collect/list', params)

/**
 * 收藏文件
 * @param params
 */
export const collectFile = (params) => post('tradeapp/user/collect', params)

/**
 * 取消收藏
 * @param params
 */
export const cancelCollect = (params) => put('tradeapp/user/collect/cancel', params)


/**
 * 获取雇员列表
 * @param params
 */
export const getEmployeeList = (params) => get('tradeapp/role/list', params)
