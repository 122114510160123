import Home from '../views/Home.vue'
import Login from '../views/login/Login.vue'
import ResetPwd from '../views/login/ResetPwd.vue'

export default [{
  path: '/login',
  name: 'login',
  component: Login,
  meta: {
    norequirelogin: true
  }
},
  {
    path: '/resetpwd',
    name: 'resetpwd',
    component: ResetPwd,
    meta: {
      norequirelogin: true
    }
  }, {
    path: '/',
    redirect: '/disk'
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    children: [{
      path: '/disk',
      name: '/disk',
      component: () => import('../views/disk/Disk')
    }, {
      path: '/disk/:code',
      name: 'disk',
      component: () => import('../views/disk/Disk')
    }, {
      path: '/recycle',
      name: 'recycle',
      component: () => import('../views/disk/Recycle')
    }, {
      path: '/recent',
      name: 'recent',
      component: () => import('../views/disk/Recent')
    }, {
      path: '/tag',
      name: 'tag',
      component: () => import('../views/disk/Tag')
    }, {
      path: '/folder',
      name: 'folder',
      component: () => import('../views/disk/Folder')
    }, {
      path: '/folder/:code',
      name: 'folderpar',
      component: () => import('../views/disk/Folder')
    }, {
      path: '/albums/:type',
      name: 'albums',
      component: () => import('../views/disk/Albums')
    }, {
      path: '/enterprise/enterpriseinfo',
      name: 'enterpriseinfo',
      component: () => import('../views/enterprise/EnterpriseInfo')
    }, {
      path: '/enterprise/department',
      name: 'department',
      component: () => import('../views/enterprise/Department')
    }, {
      path: '/enterprise/member',
      name: 'member',
      component: () => import('../views/enterprise/Member')
    }, {
      path: '/collections',
      name: 'collections',
      component: () => import('../views/disk/CollectionList')
    }]
  },
  {
    path: '*',
    redirect: '/'
  }
]
