<template>
  <div class='header'>
    <div class="header-left">
      <img src="../../assets/common/logo.png">
      <span>瓦拉格罗文件管理系统</span>
    </div>
    <div class="header-right">
      <!--<div class="header-app">移动端下载</div>-->
      <div class="header-user">
        <img :src="getUserAvatar"
             class="header-avatar">
        <span>{{getUserName}}</span>
        <!--<img src="../../assets/common/ic_select_down.png"-->
             <!--class="header-select">-->
      </div>
      <div class="header-line"></div>
      <div @click="onLogoutClick" class="fs-12 ">
        退出
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {

    }
  },
  computed: {
    getUserAvatar () {
      return this.$store.state.user.avatarImgPath || require('../../assets/common/ic_default_avatar.png')
    },
    getUserName () {
      return this.$store.state.user.userName
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    onLogoutClick () {
      this.$store.dispatch('handleLogOut')
      this.$router.replace('/login')
    },
  }
}

</script>
<style lang='scss' scoped>
.header {
  width: 100%;
  height: 64px;
  background-color: #fff;
  box-shadow: 0px 2px 8px 0px rgba(214, 210, 210, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 1000;
  .header-left {
    padding-left: 30px;
    display: flex;
    align-items: center;
    img {
      width: 60px;
      height: 42px;
      margin-right: 13px;
    }
    span {
      font-size: 20px;
      font-weight: bold;
    }
  }
  .header-right {
    padding-right: 60px;
    display: flex;
    align-items: center;
    .header-app {
      font-size: 14px;
      color: #666;
      cursor: pointer;
    }
    .header-line {
      width: 1px;
      height: 16px;
      background-color: #acb2bd;
      margin: 0 20px;
    }
    .header-user {
      display: flex;
      align-items: center;
      cursor: pointer;
      .header-avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 15px;
      }
      span {
        font-size: 14px;
        color: #666;
        margin-right: 13px;
      }
      .header-select {
        width: 13px;
        height: 8px;
      }
    }
  }
}
</style>
