import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './router'
import store from '@/store'
import iView from 'view-design'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  iView.LoadingBar.start()
  if (to.matched.some(res => res.meta.norequirelogin)) {
    next()
  } else {
    if (store.state.user.hasGetInfo) {
      next()
    } else {
      store.dispatch('getUserInfo').then(() => {
        // 拉取用户信息，通过用户权限和跳转的页面的name来判断是否有权限访问;access必须是一个数组，如：['super_admin'] ['super_admin', 'admin']
        next()
      }).catch(() => {
        store.dispatch('handleLogOut')
        let par = {
          path: '/login'
        }
        next(par)
      })
    }
  }
})
router.afterEach(() => {
  iView.LoadingBar.finish()
})
export default router
