<template>
  <div :style="{backgroundImage: 'url(' + (status===0?registerImage:loginImage) + ')'}"
       class='backImage'>
    <div class="register contentView"
         v-if="status===0">
      <h2>注册</h2>
      <div class="link">
        <p class="title">已有账号，
          <router-link replace
                       style="color:#2D8CF0;cursor: pointer;"
                       to="/login?status=1">登录
          </router-link>
        </p>
      </div>
      <ul class="m-t-40">
        <li>
          <span>用户名：</span>
          <Input @on-blur="rNameBlur"
                 class="flex-1"
                 maxlength="20"
                 placeholder="请输入真实姓名"
                 v-model="rName">
            <Icon color="#EA4F4D"
                  size="20"
                  slot="suffix"
                  type="ios-alert-outline"
                  v-show="rNameError"/>
          </Input>
          <div class="error"
               v-show="rNameError">请输入真实姓名
          </div>
        </li>
        <li>
          <span>手机号：</span>
          <Input @on-blur="rPhoneBlur"
                 class="flex-1"
                 maxlength="11"
                 placeholder="请输入手机号"
                 type="tel"
                 v-model="rPhone">
            <Icon color="#EA4F4D"
                  size="20"
                  slot="suffix"
                  type="ios-alert-outline"
                  v-show="rPhoneError"/>
          </Input>
          <div class="error"
               v-show="rPhoneError">请输入正确的手机号
          </div>
        </li>
        <li>
          <span>密码：</span>
          <Input @on-blur="rPwdBlur"
                 class="flex-1"
                 maxlength="18"
                 placeholder="请输入6-18位密码"
                 type="password"
                 v-model="rPwd">
            <Icon color="#EA4F4D"
                  size="20"
                  slot="suffix"
                  type="ios-alert-outline"
                  v-show="rPwdError"/>
          </Input>
          <div class="error"
               v-show="rPwdError">密码必须为6-18位
          </div>
        </li>
        <li>
          <span>验证码：</span>
          <Input class="flex-1"
                 placeholder="请输入验证码"
                 v-model="smsCode">
          </Input>
          <Button :disabled="timeout>0"
                  @click="getRegisterCode"
                  class="m-l-20">
            {{timeout===0?'获取验证码':`已发送（${timeout}S）`}}
          </Button>
          <div class="error"
               v-show="smsCodeError">请输入短信验证码
          </div>
        </li>
      </ul>
      <Button @click="register"
              class="submitBtn"
              type="primary">注册
      </Button>
    </div>
    <div class="login contentView"
         v-if="status===1">
      <div class="tab">
        <span :class="{current:currentTabIndex===0}">账号登录</span>
        <span :class="{current:currentTabIndex===1}"></span>
      </div>
      <div class="box">
        <ul>
          <li>
            <span>手机号：</span>
            <Input class="flex-1"
                   maxlength="11"
                   placeholder="请输入手机号"
                   type="tel"
                   v-model="lPhone">
            </Input>
          </li>
          <li>
            <span>密码：</span>
            <Input class="flex-1"
                   maxlength="18"
                   placeholder="请输入密码"
                   type="password"
                   v-model="lPwd">
            </Input>
          </li>
        </ul>
        <Button @click="login"
                class="submitBtn"
                type="primary">登录
        </Button>
        <div class="link">
          <p>忘记密码？
            <router-link style="color:#2D8CF0;cursor: pointer;"
                         to="/resetpwd">找回密码
            </router-link>
          </p>
          <p style="display: none">没有账号，
            <router-link replace
                         style="color:#2D8CF0;cursor: pointer;"
                         to="/login?status=0">立即注册
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import {getRegisterCode, register} from '../../services/userapi'

export default {
  components: {},
  data () {
    return {
      status: 1,
      registerImage: require('../../assets/login/register_background.png'),
      loginImage: require('../../assets/login/login_background.png'),
      rName: '',
      rNameError: false,
      rPhone: '',
      rPhoneError: false,
      rPwd: '',
      rPwdError: false,
      smsCode: '',
      smsCodeError: false,
      timeout: 0,
      isJoinEnt: false,
      currentTabIndex: 0,
      lPhone: '',
      lPwd: '',
    }
  },
  watch: {
    '$route.query.status': {
      handler (newValue) {
        this.init()
      },
      immediate: true
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      if (this.$route.query.status) {
        this.status = parseInt(this.$route.query.status)
      }
    },
    rNameBlur () {
      let reg = /^([\u4e00-\u9fa5]{1,20}|[a-zA-Z\.\s]{1,20})$/
      this.rNameError = !reg.test(this.rName)
    },
    rPhoneBlur () {
      let reg = /^1[345678]\d{9}$/
      this.rPhoneError = !reg.test(this.rPhone)
    },
    rPwdBlur () {
      let length = this.rPwd.length
      this.rPwdError = length < 6 || length > 18
    },
    getRegisterCode () {
      if (this.rPhone === '' || this.rPhoneError) return
      let params = {
        phone: this.rPhone
      }
      getRegisterCode(params).then(res => {
        if (res.code === 0) {
          let seconds = 60
          let timer = setInterval(() => {
            --seconds
            this.timeout = seconds
            if (seconds <= 0) {
              clearInterval(timer)
            }
          }, 1000)
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    register () {
      if (this.rName === '' || this.rNameError) return
      if (this.rPhone === '' || this.rPhoneError) return
      if (this.rPwd === '' || this.rPwdError) return
      if (this.smsCode === '') return this.smsCodeError = true
      let params = {
        name: this.rName,
        phone: this.rPhone,
        pwd: this.rPwd,
        smsCode: this.smsCode
      }
      register(params).then(res => {
        this.$Message.info(res.msg)
        if (res.code === 0) {
          this.$router.replace('/login?status=1')
          this.lPhone = this.rPhone
          this.rName = this.rPhone = this.rPwd = this.smsCode = ''
        }
      })
    },
    login () {
      if (!this.lPhone) return this.$Message.info('请输入手机号码')
      if (!this.lPwd) return this.$Message.info('请输入密码')
      this.handleLogin({
        account: this.lPhone,
        passwd: this.lPwd
      }).then(() => {
        this.$router.replace('/')
      }).catch(err => {
        this.$Message.info(err)
      })
    },
    ...mapActions([
      'handleLogin'
    ])
  }
}

</script>
<style lang='scss' scoped>
  .backImage {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .register {
      padding: 38px 64px;
    }

    .login .box {
      padding: 0 64px 40px;
    }

    .contentView {
      width: 560px;
      background-color: #fff;
      border-radius: 10px;
      opacity: 0.95;
      margin-right: 140px;

      h2 {
        font-size: 42px;
        font-weight: 500;
        margin-bottom: 10px;
      }

      .link {
        font-size: 14px;
        color: #999;
        display: flex;
        justify-content: space-between;
      }

      ul {
        li {
          display: flex;
          align-items: center;
          position: relative;
          margin-top: 30px;

          span {
            width: 70px;
          }

          .error {
            font-size: 12px;
            color: #ea4f4d;
            position: absolute;
            top: 36px;
            left: 70px;
            z-index: 10;
          }
        }
      }

      .submitBtn {
        width: 100%;
        height: 42px;
        border-radius: 29px;
        margin-top: 40px;
        margin-bottom: 20px;
      }

      .tab {
        display: flex;

        span {
          flex: 1;
          padding: 40px 0;
          font-size: 24px;
          text-align: center;
          cursor: pointer;

          &.current {
            border-top: 6px solid #2d8cf0;
            padding-top: 34px;
          }
        }
      }
    }
  }
</style>
