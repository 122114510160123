<template>
  <div style="display:flex"
       v-if="url">
    <img :src="url"
         class="small"
         v-if="size==='small'">
    <template v-if="size==='big'">
      <template v-if="fileType==='image'">
        <img :src="url"
             class="big-image">
      </template>
      <template v-else-if="fileType==='video'">
        <div class="big-video">
          <img :src="url">
          <Icon class="playIcon"
                color="#fff"
                custom="i-icon icon-player"
                size="24"
                title="标签"/>
        </div>
      </template>
      <img :src="url"
           class="big"
           v-else>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    file: Object,
    size: {
      type: String,
      default: 'small'
    }
  },
  data () {
    return {
      smallIcon: {
        folder: require('../../assets/common/icons_folder_small.png'),
        image: require('../../assets/common/icons_img_small.png'),
        video: require('../../assets/common/icons_video_small.png'),
        audio: require('../../assets/common/icons_voice_small.png'),
        txt: require('../../assets/common/icons_txt_small.png'),
        excel: require('../../assets/common/icons_excel_small.png'),
        word: require('../../assets/common/icons_word_small.png'),
        pdf: require('../../assets/common/icons_pdf_small.png'),
        ppt: require('../../assets/common/icons_ppt_small.png'),
        package: require('../../assets/common/icons_zip_small.png'),
        other: require('../../assets/common/icons_unknown_small.png'),
      },
      bigIcon: {
        folder: require('../../assets/common/icons_folder_big.png'),
        audio: require('../../assets/common/icons_voice_big.png'),
        txt: require('../../assets/common/icons_txt_big.png'),
        excel: require('../../assets/common/icons_excel_big.png'),
        word: require('../../assets/common/icons_word_big.png'),
        pdf: require('../../assets/common/icons_pdf_big.png'),
        ppt: require('../../assets/common/icons_ppt_big.png'),
        package: require('../../assets/common/icons_zip_big.png'),
        other: require('../../assets/common/icons_unknown_big.png'),
      }
    }
  },
  computed: {
    fileType () {
      let name = this.file.name
      let objType = this.file.objType
      let fileType = ''
      if (objType === 1) {
        fileType = this.$getFileType(name)
      } else if (objType === 2) {
        fileType = 'folder'
      }
      return fileType
    },
    url () {
      let path = ''
      if (this.fileType) {
        if (this.size === 'big') {
          if (this.fileType === 'image') {
            path = this.file.url + '?x-oss-process=image/resize,m_fill,h_100,w_126'
          } else if (this.fileType === 'video') {
            path = this.file.url + '?x-oss-process=video/snapshot,t_5000,f_png,w_126,h_100,m_fast'
          } else {
            path = eval(`this.${this.size}Icon.${this.fileType}`)
          }
        } else {
          path = eval(`this.${this.size}Icon.${this.fileType}`)
        }
      }
      return path
    }
  },
  mounted () {

  },
  methods: {}
}

</script>
<style lang='scss' scoped>
  .small {
    width: 26px;
    height: 26px;
  }

  .big {
    width: 68px;
    height: 68px;
  }

  .big-image {
    width: 126px;
    height: 100px;
  }

  .big-video {
    width: 126px;
    height: 100px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    .playIcon {
      width: 24px;
      height: 24px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
</style>
